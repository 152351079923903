<template>
	<keep-alive>
	
   
   <div>
	   <!-- 弹出框 -->
	   <el-dialog title="编辑" :visible.sync="dialogVisible"  :before-close="handleClose" class="editDialog">
		   <edit :userRow="userRow" @dialogVisibleFalse="dialogVisibleFalse" v-if="dialogVisible"/>
	   </el-dialog>
	   
	   <!-- 登录信息 -->
	   <global-tips></global-tips>
	   <el-card>
		   <!-- 搜索框 -->
		   <el-form v-show="showSearch" ref="queryForm" :model="queryForm" inline size="small" @submit.native.prevent>
			   <el-form-item label="单位名称" label-width="70px">
				   <el-input v-model="queryForm.keyword" placeholder="请输入单位名称" type="text" clearable
					   style="width: 200px" size="medium" @keyup.enter.native="query" />
			   </el-form-item>
			   <el-form-item label="地域" label-width="50px">
				   <el-cascader class="w-100 h-100" size="medium" clearable :options="options" v-model="areaOptions"
					   @change="areaChange">
				   </el-cascader>
			   </el-form-item>
			   <!-- 			<el-form-item label="会员身份" label-width="80px">
					<el-cascader v-model="queryForm.type" :options="types" :props="{ checkStrictly: true }" clearable></el-cascader>
			   </el-form-item> -->
			   <el-form-item label="会员类型" label-width="70px">
				   <el-cascader class="w-100 h-100" size="medium"
					   :props="{ value: 'id', label: 'name', children: 'children', multiple: true, checkStrictly: true }"
					   clearable :options="grades" v-model="typeOptions" @change="typeChange"
					   @expand-change="typeChange2">
				   </el-cascader>
			   </el-form-item>
			   <el-form-item label="资质等级" label-width="70px">
				   <el-select v-model="queryForm.zz" multiple placeholder="请选择资质等级" style="width: 100%" size="medium">
					   <el-option v-for="item in zzs" :key="item.id" :label="item.name" :value="item.id" />
				   </el-select>
			   </el-form-item>
			   <el-form-item label="企业性质" label-width="70px">
				   <el-select v-model="queryForm.qyxz" placeholder="请选择企业性质" size="medium" class="w-100">
					   <el-option v-for="(item, index) in qyxzs" :key="index" :label="item.name" :value="item.id">
					   </el-option>
				   </el-select>
			   </el-form-item>
			   </el-form-item>
			   <el-form-item label="注册资金" label-width="70px">
				   <el-select v-model="queryForm.zczj" placeholder="请选择企业性质" size="medium" class="w-100">
					   <el-option v-for="(item, index) in zczjs" :key="index" :label="item.name" :value="item.id">
					   </el-option>
				   </el-select>
			   </el-form-item>
			   </el-form-item>
			   <el-form-item label="从业人数" label-width="70px">
				   <el-select v-model="queryForm.people" placeholder="请选择从业人数" size="medium" class="w-100">
					   <el-option v-for="(item, index) in peoples" :key="index" :label="item.name" :value="item.id">
					   </el-option>
				   </el-select>
			   </el-form-item>
			   </el-form-item>
			   <el-form-item label="第一届职位" label-width="90px">
				   <el-select v-model="queryForm.one_duties" placeholder="请选择第一届职位" size="medium" class="w-100">
					   <el-option label="不选" value=""></el-option>
					   <el-option v-for="(item, index) in three_duties_list" :key="index" :label="item.name"
						   :value="item.name">
					   </el-option>
				   </el-select>
			   </el-form-item>
			   </el-form-item>
			   <el-form-item label="第二届职位" label-width="90px">
				   <el-select v-model="queryForm.two_duties" placeholder="请选择第二届职位" size="medium" class="w-100">
					   <el-option label="不选" value=""></el-option>
					   <el-option v-for="(item, index) in three_duties_list" :key="index" :label="item.name"
						   :value="item.name">
					   </el-option>
				   </el-select>
			   </el-form-item>
			   </el-form-item>
			   <el-form-item label="第三届职位" label-width="90px">
				   <el-select v-model="queryForm.three_duties" placeholder="请选择第三届职位" size="medium" class="w-100">
					   <el-option label="不选" value=""></el-option>
					   <el-option v-for="(item, index) in three_duties_list" :key="index" :label="item.name"
						   :value="item.name">
					   </el-option>
				   </el-select>
			   </el-form-item>
			   </el-form-item>
			   <el-form-item>
				   <el-button type="primary" icon="el-icon-search" size="mini" @click="query">查询</el-button>
				   <el-button icon="el-icon-refresh" size="mini" @click="resetQuery('queryForm')">重置</el-button>
			   </el-form-item>
		   </el-form>
		   <!--自定义刷新组件-->
		   <el-row :gutter="10" class="mb-1">
			   <el-button type="primary" icon="el-icon-plus" size="mini" @click.stop="handleAdd">新增</el-button>
			   <el-button type="primary" icon="el-icon-download" size="mini"
				   @click.stop="downloadTemplate">下载模板</el-button>
			   <el-upload class="upload-demo d-inline-block" ref="upload" action="#" :on-change="importExcel"
				   :on-success="importSuccess" :on-error="importError" :before-upload="beforeImportUpload" :limit="1"
				   :multiple="false" :show-file-list="false" accept=".xls, .xlsx" :auto-upload="false">
				   <el-button slot="trigger" icon="el-icon-upload2" size="small" type="primary">导入</el-button>
			   </el-upload>
			   <el-button icon="el-icon-place" size="mini" type="primary" @click="mapClick" v-loading.fullscreen.lock="fullscreenLoading">地图</el-button>
			   <el-button icon="el-icon-download" size="mini" type="primary" @click="downloadExcel">导出</el-button>
			   <right-toolbar :show-search.sync="showSearch" @queryTable="query" />
		   </el-row>
		   <!--表格-->
		   <el-table v-loading="loading" size="small" :data="list" element-loading-text="Loading" highlight-current-row
			   style="width: 100%">
			   <el-table-column label="序号" width="80" align="center">
				   <template slot-scope="scope">
					   {{ (queryForm.page - 1) * queryForm.limit + scope.$index + 1 }}
				   </template>
			   </el-table-column>
			   <el-table-column label="名称" min-width="110" align="center">
				   <template slot-scope="scope">
					   <span>{{ scope.row.name }}</span>
				   </template>
			   </el-table-column>
			   <el-table-column label="企业性质" align="center" prop="dw_xz"></el-table-column>
			   <el-table-column label="法人" align="center" prop="legal_person"></el-table-column>
			   <el-table-column label="电话" min-width="130" align="center" prop="l_phone"></el-table-column>
			   <el-table-column label="联系人" min-width="110" align="center" prop="work_person"></el-table-column>
			   <el-table-column label="联系人手机" min-width="120" align="center" prop="work_mobile"></el-table-column>
			   <el-table-column label="子账号" min-width="80" align="center" prop="child_num">
				   <template slot-scope="scope">
					   <router-link :to="{ path: '/user_child', query: { id: scope.row.id } }"
						   style="color: #2d8cf0;">{{
							   scope.row.child_num }}</router-link>
				   </template>
			   </el-table-column>
			   <el-table-column label="催缴短信" min-width="80" align="center" prop="child_num">
				   <template slot-scope="scope">
					   <span style="color: #2d8cf0;cursor: pointer;" @click="showSmsLog(scope.row.id)">{{
						   scope.row.sms_num || 0 }}</span>
				   </template>
			   </el-table-column>
			   <!-- <el-table-column label="到期时间" min-width="110" align="center" prop="end_time">
				   <template slot-scope="scope">
					   <span :class="scope.row.day <= 30 ? 'text-danger' : ''">{{ scope.row.end_time || '' }}</span>
				   </template>
			   </el-table-column> -->
			    <el-table-column label="缴费时间" min-width="110" align="center" prop="payment_date">
				   <template slot-scope="scope">
					   <span :class="scope.row.day <= 30 ? 'text-danger' : ''">{{ scope.row.payment_date || '' }}</span>
				   </template>
			   </el-table-column>
			   <el-table-column label="缴费" min-width="100" align="center">
				   <template slot-scope="scope">
					   <router-link :to="{ path: '/user_jfjl', query: { id: scope.row.id } }"
						   style="color: #2d8cf0;">缴费记录</router-link>
				   </template>
			   </el-table-column>
			   <el-table-column label="地址" min-width="110" align="center" prop="address"></el-table-column>
			   <el-table-column label="注册资金(万)" min-width="110" align="center" prop="reg_money"></el-table-column>
			   <el-table-column label="从业人数(人)" min-width="110" align="center" prop="people_num"></el-table-column>
			   <el-table-column label="第三届职位" min-width="120" align="center" prop="three_duties">
				   <template slot-scope="scope">
					   <el-tag size="mini" type="success" v-if="scope.row.three_duties">{{ scope.row.three_duties ||
						   '' }}</el-tag>
				   </template>
			   </el-table-column>
			   <el-table-column label="承装" min-width="110" align="center" prop="cz_grade"></el-table-column>
			   <el-table-column label="承修" min-width="110" align="center" prop="cx_grade"></el-table-column>
			   <el-table-column label="承试" min-width="110" align="center" prop="cs_grade"></el-table-column>
			   <el-table-column label="类型" min-width="140" align="center" prop="grade_name"></el-table-column>
			   <el-table-column label="状态" min-width="60" align="center">
				   <template slot-scope="scope">
					   <el-tag v-if="scope.row.status === 1" size="mini" type="success">正常</el-tag>
					   <el-tag v-if="scope.row.status === 2" size="mini" type="danger">停用</el-tag>
				   </template>
			   </el-table-column>
			   <!-- <el-table-column label="缴费" min-width="100" align="center">
				   <template slot-scope="scope">
					   <router-link :to="{ path: '/user_jfjl', query: { id: scope.row.id } }"
						   style="color: #2d8cf0;">缴费记录</router-link>
				   </template>
			   </el-table-column> -->
			   <el-table-column align="center" prop="utime" label="时间" min-width="175">
				   <template slot-scope="scope">
					   <i class="el-icon-time" />
					   <span>{{ scope.row.utime }}</span>
				   </template>
			   </el-table-column>
			   <el-table-column label="操作" min-width="200" align="center" fixed="right">
				   <template slot-scope="scope">
					   <el-tooltip class="item" effect="dark" content="查看" placement="top">
						   <el-button type="success" icon="el-icon-view" size="mini" circle
							   @click.stop="handleView(scope.row)"></el-button>
					   </el-tooltip>
					   <el-tooltip class="item" effect="dark" content="编辑" placement="top">
						   <el-button type="info" icon="el-icon-edit" size="mini" circle
							   @click.stop="handleEdit(scope.row)"></el-button>
					   </el-tooltip>
					   <el-tooltip class="item" effect="dark" content="催缴短信" placement="top">
						   <el-button type="primary" icon="el-icon-position" size="mini" circle
							   @click.stop="handleSms(scope.row)"></el-button>
					   </el-tooltip>
					   <el-tooltip class="item" effect="dark" content="停用" placement="top">
						   <el-button type="warning" icon="el-icon-setting" size="mini" circle
							   @click.stop="handleSetting(scope.row)"></el-button>
					   </el-tooltip>
					   <el-tooltip class="item" effect="dark" content="删除" placement="top">
						   <el-button type="danger" icon="el-icon-delete" size="mini" circle
							   @click.stop="handleDelete(scope.row)"></el-button>
					   </el-tooltip>
				   </template>
			   </el-table-column>
		   </el-table>
		   <pagination :total="total" auto-scroll :page.sync="queryForm.page" :limit.sync="queryForm.limit"
			   @pagination="query" />
	   </el-card>

	   <!-- 停用 -->
	   <el-dialog :visible.sync="operate" width="600px" :close-on-click-modal="false" append-to-body :title="title">
		   <el-form ref="operate" :model="operateForm" label-width="80px" size="small" :rules="operateRules">
			   <el-form-item label="停用原因" prop="stop_desc">
				   <el-input v-model="operateForm.stop_desc" placeholder="请输入停用原因" type="textarea" :rows="3"
					   clearable />
			   </el-form-item>
		   </el-form>
		   <div slot="footer" class="dialog-footer">
			   <el-button size="mini" @click="operateCancel">取 消</el-button>
			   <el-button type="primary" size="mini" @click="handleOperateSubmit">确 定</el-button>
		   </div>
	   </el-dialog>
	   <!-- 删除 -->
	   <el-dialog :visible.sync="deleteDialog" width="600px" :close-on-click-modal="false" append-to-body
		   :title="title">
		   <el-form ref="deleteDialog" :model="deleteForm" label-width="80px" size="small" :rules="deleterRules">
			   <el-form-item label="删除原因" prop="del_desc">
				   <el-radio-group v-model="deleteForm.del_desc">
					   <el-radio label="退会">1.退会</el-radio>
					   <el-radio label="经营不善">2.经营不善</el-radio>
					   <el-radio label="两年未缴费">3.两年未缴费</el-radio>
					   <el-radio label="重复">4.重复</el-radio>
					   <el-radio label="其他">5.其他</el-radio>
				   </el-radio-group>
				   <!-- <el-input v-model="deleteForm.del_desc" placeholder="请输入删除原因" type="textarea" :rows="3" clearable /> -->
			   </el-form-item>
		   </el-form>
		   <div slot="footer" class="dialog-footer">
			   <el-button size="mini" @click="deleteCancel">取 消</el-button>
			   <el-button type="primary" size="mini" @click="handleDeleteSubmit">确 定</el-button>
		   </div>
	   </el-dialog>
	   <!-- 日志 -->
	   <el-dialog :visible.sync="openLog" width="800px" :close-on-click-modal="false" append-to-body :title="title">
		   <el-table :data="logData" style="width: 100%">
			   <el-table-column label="ID" align="center" prop="id">
			   </el-table-column>
			   <el-table-column label="用户" align="center" prop="name">
			   </el-table-column>
			   <el-table-column label="手机" align="center" prop="mobile">
			   </el-table-column>
			   <el-table-column label="描述" align="center" prop="msg">
			   </el-table-column>
			   <el-table-column label="时间" align="center" prop="ctime">
			   </el-table-column>
		   </el-table>
		   <!-- 分页 -->
		   <span slot="footer" class="dialog-footer">
			   <div class="px-2" style="flex: 1;">
				   <pagination :total="page.total" auto-scroll :page.sync="page.page" :limit.sync="page.size"
					   @pagination="getLog" />
			   </div>
		   </span>
	   </el-dialog>
	   <tmap ref="MapWin" v-if="mapVisible" :mapPosition="mapPosition" :infoWindows="infoWindows"
		   @closeMapWin="mapVisible = false" @setMap="setMap" />
   </div>
</keep-alive>
</template>

<script>
import GlobalTips from "@/components/GlobalTips";
import common from "@/common/mixins/common.js"
import tmap from "@/components/common/t_map.vue";
import { regionData, CodeToText, TextToCode } from 'element-china-area-data'
import edit from "./edit_user.vue";
export default {
   inject: ['app'],
   mixins: [common],
   components: {
	   GlobalTips,
	   tmap,
	   edit
   },
   data() {
	   return {
		   fullscreenLoadingFlag:false,
		   fullscreenLoading: false,
		   userRow:'',
		   dialogVisible: false,
		   deleteRadio: '其他',
		   preUrl: 'user',
		   // autoRequest: false,
		   queryForm: {
			   page: 1,
			   limit: 10,
			   grades: [],
			   keyword: '',
			   area_id: '',
			   zz: [],
			   qyxz: '',
			   zczj: '',
			   one_duties: '',
			   two_duties: '',
			   three_duties: '',
			   type: '',
		   },
		   options: regionData,
		   areaOptions: [],
		   grades: [],
		   gradesOptions: [],
		   typeOptions: [],
		   zzs: [
			   { id: '', name: '全部' },
			   { id: '一', name: '一级' },
			   { id: '二', name: '二级' },
			   { id: '三', name: '三级' },
			   { id: '四', name: '四级' },
			   { id: '五', name: '五级' },
		   ],
		   qyxzs: [
			   { id: '', name: '全部' },
			   { id: '国有企业', name: '国有企业' },
			   { id: '民营', name: '民营' },
		   ],
		   zczjs: [
			   { id: '', name: '全部' },
			   { id: 1, name: '1万-50万' },
			   { id: 2, name: '50万-100万' },
			   { id: 3, name: '100万-500万' },
			   { id: 4, name: '500万-1000万' },
			   { id: 5, name: '1000万以上' },
		   ],
		   peoples: [
			   { id: '', name: '全部' },
			   { id: 1, name: '1人-50人' },
			   { id: 2, name: '50人-100人' },
			   { id: 3, name: '100人-500人' },
			   { id: 4, name: '500人-1000人' },
			   { id: 5, name: '1000人以上' },
		   ],
		   three_duties_list: [
			   { id: 1, name: '会长' },
			   { id: 2, name: '副会长' },
			   { id: 3, name: '常务理事' },
			   { id: 4, name: '监事长' },
			   { id: 5, name: '监事' },
			   { id: 6, name: '理事' },
			   { id: 7, name: '会员' },
		   ],
		   operate: false,
		   operateForm: {
			   id: '',
			   status: 2,
			   stop_desc: ''
		   },
		   operateRules: {
			   stop_desc: [
				   { required: true, message: '请输入停用原因', trigger: 'blur' }
			   ],
		   },
		   deleteDialog: false,
		   deleteForm: {
			   id: '',
			   del_desc: ''
		   },
		   deleterRules: {
			   del_desc: [
				   { required: true, message: '请输入删除原因', trigger: 'blur' }
			   ],
		   },
		   mapVisible: false,
		   mapPosition: null,
		   infoWindows: [],
		   log_user_id: '',
		   openLog: false,
		   logData: [],
		   page: {
			   page: 1,
			   limit: 10,
			   total: 0,
			   keyword: '',
		   },
		   types: [],
	   }
   },
   mounted() {
	   this.getGrades()
	   this.getUserTypes()
	   

   },
   methods: {
	mapClick(){
		
		//数据已接收到
		if(this.fullscreenLoadingFlag == true){
			this.fullscreenLoading = false;
			this.mapVisible =true
		}
		// 数据未接收到
		else {
			this.getMapPosition()
			this.fullscreenLoading = true;
			
		}
		
		
	},
	   handleClose(done) {
	   this.$confirm('确认关闭？')
		 .then(_ => {
		   done();
		 })
		 .catch(_ => {});
	 },
	   getUserTypes() {
		   this.axios.get('/manage/user_grade/types').then(res => {
			   if (res.status) {
				   this.types = res.data
			   }
		   })
	   },
	   showSmsLog(id) {
		   this.log_user_id = id
		   this.page.page = 1
		   this.logData = []
		   this.getLog()
		   this.$nextTick(() => {
			   this.title = '短信记录'
			   this.openLog = true
		   })
	   },
	   getLog() {
		   this.axios.get('/manage/user/sms_log', {
			   params: {
				   user_id: this.log_user_id,
				   page: this.page.page,
				   limit: this.page.limit,
				   keyword: this.page.keyword,
			   }
		   }).then(res => {
			   this.page.total = res.data.total
			   this.logData = res.data.data
		   })
	   },
	   resetQuery(formName) {
		   this.reset()
		   this.areaOptions = []
		   this.resetForm(formName)
		   this.query()
	   },
	   reset() {
		   this.queryForm = {
			   page: 1,
			   limit: 10,
			   grade: [],
			   keyword: '',
			   area_id: '',
			   zz: [],
			   qyxz: '',
			   zczj: '',
			   three_duties: '',
		   }
		   this.resetForm('form')
	   },
	   getGrades() {
		   this.axios.get('/manage/user_grade/tree').then(res => {
			   if (res.status) {
				   let grades = res.data
				   this.grades = grades
			   }
		   })
	   },
	   areaChange() {
		   this.queryForm.area_id = this.areaOptions[2]
	   },
	   typeChange(e) {
		   this.queryForm.grades = e
	   },
	   typeChange2(e) {
		   this.queryForm.grades.push(e)
	   },
	   handleSetting(row) {
		   this.operate = true
		   this.title = '停用'
		   this.operateForm.id = row.id
	   },
	   operateCancel() {
		   this.operate = false
	   },
	   handleOperateSubmit() {
		   this.$refs.operate.validate(valid => {
			   if (valid) {
				   this.axios.post('/manage/' + this.preUrl + '/operate', this.operateForm).then(res => {
					   if (res.status) {
						   this.$message.success('设置成功')
						   this.operate = false
						   this.getList()
					   } else {
						   this.$message.error(res.msg)
					   }
				   })
			   }
		   })
	   },
	   handleView(row) {
		   this.$router.push({
			   path: "/user_detail",
			   query: {
				   id: row.id
			   }
		   })
	   },
	   handleAdd() {
		   this.$router.push({
			   path: "/user_edit",
			   query: {}
		   })
	   },
	   dialogVisibleFalse() {
		   this.dialogVisible = false
		   this.query()
	   },
	   handleEdit(row) {
		   this.dialogVisible = true
		   this.userRow = row
		   console.log(row);
		   // this.$router.push({
		   // 	path: "/user_edit",
		   // 	query: {
		   // 		user: JSON.parse(JSON.stringify(row))
		   // 	}
		   // })
	   },
	   handleDelete(row) {
		   this.deleteForm = {
			   id: '',
			   del_desc: ''
		   }
		   this.deleteDialog = true
		   this.title = '删除'
		   this.deleteForm.id = row.id
	   },
	   deleteCancel() {
		   this.deleteDialog = false
	   },
	   handleDeleteSubmit() {
		   this.$refs.deleteDialog.validate(valid => {
			   if (valid) {
				   this.axios.post('/manage/' + this.preUrl + '/toDel', this.deleteForm).then(res => {
					   if (res.status) {
						   this.$message.success('删除成功')
						   this.deleteDialog = false
						   this.getList()
					   } else {
						   this.$message.error(res.msg)
					   }
				   })
			   }
		   })
	   },
	   downloadTemplate() {
		   this.axios.get('/manage/user/downloadTemplate', {
			   responseType: 'arraybuffer',
		   }).then(res => {
			   const aLink = document.createElement("a");
			   let blob = new Blob([res], { type: "application/vnd.ms-excel" });
			   aLink.href = URL.createObjectURL(blob);
			   aLink.download = '导入模板.xlsx';
			   aLink.click();
			   document.body.appendChild(aLink);
		   })
	   },
	   downloadExcel(row) {
		   this.axios.get('/manage/user/downloadExcel', {
			   responseType: 'arraybuffer',
			   params: this.queryForm
		   }).then(res => {
			   console.log(res)
			   const aLink = document.createElement("a");
			   let blob = new Blob([res], { type: "application/vnd.ms-excel" });
			   aLink.href = URL.createObjectURL(blob);
			   aLink.download = '会员列表.xlsx';
			   aLink.click();
			   document.body.appendChild(aLink);
		   })
	   },
	   importExcel(e) {
		   //获取用户选择的文件
		   const file = e.raw
		   let formdata = new FormData()
		   formdata.append('file', file)
		   //创建loading对象开始遮罩
		   const rLoading = this.openLoading();
		   this.axios.post('/manage/user/importExport', formdata).then(res => {
			   this.$refs.upload.clearFiles()
			   //请求结束关闭loading
			   rLoading.close();
			   if (res.status) {
				   this.$message.success('导入成功')
				   this.getList()
			   } else {
				   this.$message.success('导入失败')
			   }

		   }).catch(function (error) {
			   console.log(error);
			   rLoading.close();
		   });
	   },
	   beforeImportUpload(file) {
		   let fileArr = file.name.split('.')
		   let suffix = fileArr[fileArr.length - 1]
		   //只能导入.xls和.xlsx文件
		   if (!/(xls|xlsx)/i.test(suffix)) {
			   this.$message('文件格式不正确')
			   return false
		   }
		   return true
	   },
	   importSuccess(e) {
		   console.log(e)
		   // 上传成功之后清除历史记录;否则只能上传一次
		   this.$refs.upload.clearFiles()
	   },
	   importError(e) {
		   console.log(e)
		   this.$refs.upload.clearFiles()
	   },
	   getMapPosition() {
		   this.axios.post('/manage/user/map_position', {}).then(res => {
			   if (res.status) {
				   this.infoWindows = res.data
				   this.fullscreenLoadingFlag = true
				   this.fullscreenLoading = false;
				   this.mapVisible =true
				   
			   }
		   })
	   },
	   setMap(data) {
		   this.mapPosition = data.position
		   // this.form.coordinate=data.position.join(",");
		   // this.form.address=data.address;
		   this.mapVisible = false;
	   },
	   handleSms(row) {
		console.log(row);
		if(row.work_mobile == "") {
			this.$message.error('该会员未绑定联系人手机号，请完善信息')
			return false;
			
		} else { this.$confirm(`<p>联系人：${row.work_person}</p>
		  <p> 联系电话：${row.work_mobile}</p>
确定要给该会员发送催缴短信？`, '提示', {
			   confirmButtonText: '确定',
			   cancelButtonText: '取消',
			   type: 'warning',
			   dangerouslyUseHTMLString: true
		   }).then(() => {
			   this.axios.post('/manage/user/sms', {
				   id: row.id
			   }).then(res => {
				   if (res.status) {
					   this.$message({
						   message: '发送成功',
						   type: 'success'
					   })
				   }
				   this.loading = false
			   })
		   })
	   }}
		  
   }
}
</script>

<style scoped>
:deep .el-cascader .el-input .el-input__inner {
   margin-bottom: 8px !important;
}
:deep .el-dialog{
   width: 80vw !important;
   
}
</style>